'use client';
import { hideModal } from '@/redux/navigationSlice';
import { RootState } from '@/redux/store';
import { Button, Stack, Typography } from '@mui/material';
import { repaymentTerms } from '@repo/onb-api';
import { Table, TableDataType, TableHeadersType } from '@repo/ui';
import { formatter } from '@repo/utils';
import { useDispatch, useSelector } from 'react-redux';

/**
 * Renders a body showing the new order selected plan & rates for a Modal component.
 *
 * @return {JSX.Element} The rendered SelectedPlanBody component.
 */
export const SelectedPlanBody = (): JSX.Element => {
  const dispatch = useDispatch();
  const { steps } = useSelector((state: RootState) => state.flowdata);
  const stepData = steps.find(({ name }) => name === 'REPAYMENT_TERMS') as repaymentTerms;
  const paymentSchedule = stepData.variables.installments?.find(
    ({ planId }) => planId === stepData?.variables?.installmentPlanId,
  )?.paymentSchedule;

  const headers: TableHeadersType = [
    { label: 'Vencimiento', align: 'left' },
    { label: 'Cuota', align: 'center' },
    { label: 'Monto', align: 'right' },
  ];

  const tableData = paymentSchedule?.map(({ dueDate, number, amount }) => ({
    rowData: [
      {
        name: 'expiration',
        value: (
          <Typography variant="placeholder" fontSize={14} fontWeight={300}>
            {new Date(dueDate) <= new Date() ? 'Hoy!' : formatter(dueDate, 'date')}
          </Typography>
        ),
        align: 'left',
      },
      {
        name: 'quote',
        value: (
          <Typography variant="placeholder" fontSize={14} fontWeight={300}>
            {number}
          </Typography>
        ),
        align: 'center',
      },
      {
        name: 'amount',
        value: (
          <Typography variant="placeholder" fontSize={14} fontWeight={300}>
            {formatter(amount, 'currency')}
          </Typography>
        ),
        align: 'right',
      },
    ],
  }));
  return (
    <>
      <Table
        headers={headers}
        tableData={tableData as TableDataType[]}
        rows={tableData ? tableData.length : 0}
        hideActions
        hidePagination
      />
      <Stack gap={2} width="100%" mt={2}>
        <Button
          variant="outlined"
          onClick={(e) => {
            e.preventDefault();
            dispatch(hideModal());
          }}
        >
          Cerrar
        </Button>
      </Stack>
    </>
  );
};
