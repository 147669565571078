import { AnyAction, combineReducers, configureStore } from '@reduxjs/toolkit';
// import userdataReducer from "./userdataSlice";
import errorSlice from './errorSlice';
import flowReducer from './flowSlice';
import navigationReducer from './navigationSlice';

const resettableRootReducer = (state: ReturnType<typeof rootReducer> | undefined, action: AnyAction) => {
  if (action.type === 'store/reset') {
    document.cookie = `instanceId=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;`;
    return rootReducer(undefined, action);
  }

  return rootReducer(state, action);
};

const rootReducer = combineReducers({
  navigationdata: navigationReducer,
  flowdata: flowReducer,
  errorState: errorSlice,
});

export const store = configureStore({
  reducer: resettableRootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

export type RootState = ReturnType<typeof rootReducer>;
