import { hideToast } from '@/redux/navigationSlice';
import { RootState } from '@/redux/store';
import { Toast } from '@repo/ui';
import { useDispatch, useSelector } from 'react-redux';

/**
 * Renders a Toast component connected with Redux.
 *
 * @return {JSX.Element} The rendered Toast component.
 */
export const OnbToast = () => {
  const dispatch = useDispatch();
  const { open, kind, text } = useSelector((state: RootState) => state.navigationdata.toastData);
  return <Toast open={open} kind={kind} text={text} close={() => dispatch(hideToast())} />;
};
