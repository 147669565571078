'use client';
import { OnbModal } from '@/components/modal';
import OnbDevTools from '@/components/onbDevTools';
import { OnbToast } from '@/components/toast';
import { Provider } from 'react-redux';
import { store } from './store';

export function ReduxProvider({ children }: { children: React.ReactNode }) {
  return (
    <Provider store={store}>
      <OnbModal />
      <OnbToast />
      {children}
      {process.env.NEXT_PUBLIC_ENABLE_DEVTOOLS && <OnbDevTools />}
    </Provider>
  );
}
