'use client';
import { hideModal } from '@/redux/navigationSlice';
import { Button, Stack } from '@mui/material';
import { getOrderCredentialJWT } from '@repo/ecommerce-api';
import { Doodles } from '@repo/ui';
import { deleteClientCookie, getClientCookie, parseJwt } from '@repo/utils';
import { useRouter } from 'next/navigation';
import { useDispatch } from 'react-redux';

/**
 * Renders a back to store body for a Modal component with redirect logic.
 *
 * @return {JSX.Element} The rendered ModalCancelBody component.
 */
export const BackToStoreBody = (): JSX.Element => {
  const dispatch = useDispatch();
  const router = useRouter();
  return (
    <>
      <Doodles type="confirmation" />
      <Stack gap={2} width="100%" mt={2}>
        <Button
          onClick={() => {
            deleteClientCookie('instanceId');
            const decodedJwt = parseJwt(getClientCookie('tokenData') || '') as getOrderCredentialJWT;
            router.push(decodedJwt.returnURLError || '/');
          }}
        >
          Volver a la tienda
        </Button>
        <Button variant="inverted" onClick={() => dispatch(hideModal())}>
          Cancelar
        </Button>
      </Stack>
    </>
  );
};
