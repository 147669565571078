'use client';

import { clearErrors } from '@/redux/errorSlice';
import { pushStepVariables } from '@/redux/flowSlice';
import { RootState } from '@/redux/store';
import { onbCompleteFlow, steps, variables } from '@repo/onb-api';
import { DevTools, DevToolsAction } from '@repo/ui';
import { deleteClientCookie, getClientCookie, setClientCookie } from '@repo/utils';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

/**
 * Renders the DevTools component with onboarding custom actions and autocomplete logic.
 *
 * @return {JSX.Element} The rendered OnbDevTools component.
 */
export const OnbDevTools = () => {
  const [forceMock, setForceMock] = useState<string>();
  const [forceAutocomplete, setForceAutocomplete] = useState<string>('false');
  const dispatch = useDispatch();
  const stepName = useSelector((state: RootState) => state.navigationdata.currentStep);
  const initialized = useSelector(
    (state: RootState) => state?.flowdata?.steps?.find((s) => s.name === stepName)?.initialized || false,
  );

  const updateSetting = (key: string, value: string) => {
    setClientCookie(key, value);
    syncSettings();
  };

  const syncSettings = () => {
    const forceAutocompleteCookie = getClientCookie('FORCE_AUTOCOMPLETE');
    const forceMockCookie = getClientCookie('FORCE_MOCK');
    setForceMock(forceMockCookie || 'false');
    setForceAutocomplete(forceAutocompleteCookie || 'false');
  };

  useEffect(() => {
    syncSettings();
  }, []);

  useEffect(() => {
    if (initialized && forceAutocomplete === 'true') {
      stepAutocomplete();
    }
  }, [forceAutocomplete, initialized]);

  const stepAutocomplete = () => {
    const mockStep = onbCompleteFlow.steps.find((s) => s.name === stepName);
    const variables = Object.entries(mockStep?.variables || {}) as [keyof variables, string][];
    if (mockStep && variables.length > 0) {
      variables.map((variable) => {
        dispatch(
          pushStepVariables({
            stepName: mockStep.name as keyof steps,
            variable: variable[0],
            value: variable[1],
          }),
        );
      });
    }
    dispatch(clearErrors());
  };

  const autoCompleteAction: DevToolsAction = {
    icon: 'edit_note',
    name: 'Autocomplete (Long Press Toggle)',
    onClick: () => stepAutocomplete(),
    active: forceAutocomplete === 'true',
    onLongClick: () => {
      updateSetting('FORCE_AUTOCOMPLETE', forceAutocomplete === 'true' ? 'false' : 'true');
    },
    reset: () => {
      deleteClientCookie('FORCE_AUTOCOMPLETE');
      syncSettings();
    },
  };

  const forceMockAction: DevToolsAction = {
    icon: 'theater_comedy',
    name: forceMock === 'true' ? 'Disable Mock' : 'Enable Mock',
    active: forceMock === 'true',
    onClick: () => updateSetting('FORCE_MOCK', forceMock === 'true' ? 'false' : 'true'),
    reset: () => {
      deleteClientCookie('FORCE_MOCK');
      syncSettings();
    },
  };

  const metamapBypassAction: DevToolsAction = {
    icon: 'frame_person_off',
    name: 'Metamap Bypass',
    onClick: () => document.getElementById('metamapSubmit')?.click(),
  };

  let actions = [autoCompleteAction, forceMockAction];

  if (stepName === 'IDENTITY_VERIFICATION_START') {
    actions = [...actions, metamapBypassAction];
  }

  return <DevTools customActions={actions} />;
};

export default OnbDevTools;
