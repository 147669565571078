'use client';
import { hideModal } from '@/redux/navigationSlice';
import { Button, Stack } from '@mui/material';
import { Doodles } from '@repo/ui';
import { deleteClientCookie, getClientCookie } from '@repo/utils';
import { useDispatch } from 'react-redux';

/**
 * Renders a cancel body for a Modal component with flow reset logic.
 *
 * @return {JSX.Element} The rendered ModalCancelBody component.
 */
export const ModalCancelBody = (): JSX.Element => {
  const dispatch = useDispatch();
  return (
    <>
      <Doodles type="confirmation" />
      <Stack gap={2} width="100%" mt={2}>
        <Button
          onClick={(e) => {
            e.preventDefault();
            deleteClientCookie('instanceId');
            //@todo - check if interval is needed
            const interval = setInterval(() => {
              if (!getClientCookie('instanceId')) {
                clearInterval(interval);
                window.location.href = '/';
                // dispatch(resetFlow());
                // dispatch(hideModal());
              }
            }, 500);
          }}
        >
          Volver al inicio
        </Button>
        <Button variant="inverted" onClick={() => dispatch(hideModal())}>
          Cancelar
        </Button>
      </Stack>
    </>
  );
};
