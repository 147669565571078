import { hideModal, modalBodyType } from '@/redux/navigationSlice';
import { RootState } from '@/redux/store';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Modal } from '@repo/ui';
import { useDispatch, useSelector } from 'react-redux';
import { BackToStoreBody } from './backToStoreBody';
import { ModalCancelBody } from './cancelBody';
import { SelectedPlanBody } from './selectedPlanBody';

const bodySelector = (body: modalBodyType) => {
  switch (body) {
    case 'selectedPlan':
      return <SelectedPlanBody />;
    case 'cancel':
      return <ModalCancelBody />;
    case 'backToStore':
      return <BackToStoreBody />;
    default:
      return <></>;
  }
};

/**
 * Renders a Modal component with title and body based on redux.
 *
 * @return {JSX.Element} The rendered Modal component.
 */
export const OnbModal = () => {
  const modalData = useSelector((state: RootState) => state.navigationdata.modalData);
  const dispatch = useDispatch();
  const { open, body, title, onClose } = modalData;
  const BodyComponent = bodySelector(body);
  const handleClose = () => {
    dispatch(hideModal());
    onClose?.();
  };
  return (
    <Modal
      open={open}
      onClose={() => {
        handleClose();
      }}
    >
      <Grid
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 4,
          py: { xs: 2, md: 4 },
          px: { xs: 2, md: 6 },
        }}
      >
        <Typography variant="h1">{title}</Typography>
        {BodyComponent}
      </Grid>
    </Modal>
  );
};
